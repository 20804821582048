import React from 'react';
import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import Gist from 'react-gist';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

import PostImage from '../PostImage';
import AudioNote from '../AudioNote';

const MarkDown = (props) => {
	return (
		<ReactMarkdown
			// eslint-disable-next-line react/no-children-prop
			children={props.markdown}
			rehypePlugins={[rehypeRaw]}
			remarkPlugins={[remarkGfm]}
			components={{
				gist({ node, ...props }) {
					return <Gist id={props.id} />;
				},
				img({ node, inline, className, children, ...props }) {
					return (
						<PostImage
							src={props.src}
							desc={props.desc}
							alt={props.alt}
							style={props.style}
						/>
					);
				},
				audio({ node, inline, className, children, ...props }) {
					return (
						<AudioNote 
							src={props.src}
							label={props.label}
						/>
					)
				},
				code({ node, inline, className, children, ...props }) {
					const match = /language-(\w+)/.exec(className || '');
					return !inline && match ? (
						<SyntaxHighlighter
							// eslint-disable-next-line react/no-children-prop
							children={String(children).replace(/\n$/, '')}
							style={tomorrow}
							language={match[1]}
							PreTag="div"
							{...props}
						/>
					) : (
						<code className={className} {...props}>
							{children}
						</code>
					);
				},
			}}
		/>
	);
};

MarkDown.propTypes = {
	markdown: PropTypes.string,
};

export default MarkDown;
