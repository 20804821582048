import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ImageContainer = (props) => {
	const [showOverlay, setShowOverlay] = useState(false);

	return (
		<div
			className="image__container"
			onMouseEnter={() => setShowOverlay(true)}
			onMouseLeave={() => setShowOverlay(false)}
		>
			<div className="copy">{props.url}</div>
			<img src={props.url} alt={props.name} />
			{showOverlay ? (
				<div
					className="image__container--overlay"
					onClick={() => {
						props.onClick(props.url);
					}}
				>
					<div>Copy Url</div>
				</div>
			) : null}
		</div>
	);
};

ImageContainer.propTypes = {
	name: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};

export default ImageContainer;
