import axios from 'axios';

const authToken = window.localStorage.AUTH_TOKEN || '';

const config = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

config.defaults.headers.common.Authorization = 'Bearer ' + authToken;

config.interceptors.response.use(
	function (resp) {
		return resp;
	},
	function (error) {
		if (error.response.status === 403 || error.response.status === 401) {
			window.location.href = '/login';
		}
		return Promise.reject(error);
	}
);

export default config;
