import React from 'react';
import NavBar from '../../components/NavBar';

import './styles.scss';

const HomePage = (props) => {
	return (
		<div className="home__container">
			<div className='component_circle'></div>
			<section id="home">
				<NavBar />
				<div className="content__container">
					<div
						className="content__container--img"
						style={{
							backgroundImage: `url(${process.env.REACT_APP_PROFILE_URL})`,
						}}
					></div>
					<div className="content__container--title">
						<span className="primary">Hi, I am Abhishek</span>
						<span className="secondary">
							Software Engineer, Bibiliophile, Finding myself one
							trip at a time
						</span>
					</div>
				</div>
			</section>
		</div>
	);
};

export default HomePage;
