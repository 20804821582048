import React from 'react';

import { Link } from 'react-router-dom';
import ThemeSetter from '../ThemeSetter';
import './styles.scss';

const BlogHeader = (props) => {
	return (
		<div className="blog__header" id="blog__head">
			<div className="header__title">
				<span className="header__title--primary">Abhishek Tiwari</span>
				<span className="header__title--secondary">
					Ramblings about Tech, Finance &amp; Books
				</span>
			</div>

			<div className="header__actions">
				<div className="header__actions--nav">
					<ul>
						<li>
							<Link to="/">Posts</Link>
						</li>
						<li>
							<Link to="/projects">Projects</Link>
						</li>
						<li>
							<Link to="/workstation">PC Setup</Link>
						</li>
						<li>
							<a
								href={process.env.REACT_APP_CLIENT_URL}
								rel="noreferrer"
								target="_blank"
							>
								Portfolio
							</a>
						</li>
					</ul>
				</div>
				<div className="header__actions--theme">
					<ThemeSetter
						bgColor="#eee"
						ballColor="#CB0162"
						sunColor="#E67E22"
					/>
				</div>
			</div>
		</div>
	);
};

export default BlogHeader;
