import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state) => state.blog || initialState;

export const selectPosts = createSelector(
	[selectDomain],
	(blogState) => blogState.posts
);

export const selectIsLoading = createSelector(
	[selectDomain],
	(blogState) => blogState.isLoading
);

export const selectLoadMore = createSelector(
	[selectDomain],
	(blogState) => blogState.loadMore
);

export const selectLast = createSelector(
	[selectDomain],
	(blogState) => blogState.from
);

export const selectPageContent = createSelector(
	[selectDomain],
	(blogState) => blogState.pageContent
);
