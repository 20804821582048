import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import MarkDown from '../../../components/MarkDown';
import Loader from '../../../components/Loader';
import NotFound from '../../../components/NotFound';
import { getMonth } from '../../../../utils/common';
import getBlog from '../../../../services/getBlog';
import { Tag } from '../../../components/Tag';

import './styles.scss';

const Post = (props) => {
	const params = useParams();
	const [postData, setPostData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getBlog({ blogId: params.id })
			.then((response) => {
				if (response.data && response.data.length > 0) {
					setPostData(response.data[0]);
				} else {
					setPostData(null);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setPostData(null);
				setIsLoading(false);
			});
	}, []);

	let content;
	if (isLoading) {
		content = (
			<div className="spinner-loader">
				<Loader />
			</div>
		);
	} else if (!isLoading && !postData) {
		content = <NotFound />;
	} else {
		const parsedDate = moment(postData.timestamp);
		const day = parsedDate.format('D');
		const month = getMonth(parsedDate.format('M'));
		const year = parsedDate.format('YYYY');
		console.log(postData);
		content = (
			<>
				<div className="post__container--header">
					<div className="header-date">
						<span className="date">{day}</span>
						<span className="month">{month}</span>
						<span className="year">{year}</span>
					</div>
					<div className="header-body">
						<span className="title">{postData.title}</span>
					</div>
				</div>

				<div className="post__container--body">
					<div className="tags__container">
						{postData.tags && postData.tags.length > 0 ? (
							<div className="tags__container--label">Tags: </div>
						) : null}
						<ul className="tags__container--list">
							{postData.tags.map((item) => {
								return <Tag data={item} key={item._id} />;
							})}
						</ul>
					</div>
					<MarkDown markdown={postData.content} />
				</div>
			</>
		);
	}

	return <div className="post__container">{content}</div>;
};

export default Post;
