import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../components/Button';
import login from '../../../../services/login';

import { deleteFromStorage } from '../../../../utils/common';

import './styles.scss';

const Login = (props) => {
	const history = useHistory();

	const emailRef = useRef();
	const passRef = useRef();
	const [error, setError] = useState(null);

	useEffect(() => {
		if (window.location.pathname.includes('logout')) {
			deleteFromStorage('AUTH_TOKEN');
			history.replace('/login');
		}
	}, []);

	const onLoginHandler = async (event) => {
		event.preventDefault();
		const email = emailRef.current.value;
		const password = passRef.current.value;

		if (!email || !password) {
			setError('Invalid Credentials');
			return;
		}

		const response = await login({
			email,
			password,
		});

		if (response.data) {
			const authToken = response.data.token;
			localStorage.setItem('AUTH_TOKEN', authToken);
			setError(null);

			history.push('/');
		} else {
			setError('Something went wrong!');
		}
	};

	return (
		<div className="login__container">
			<div className="login__container--form">
				<span className="form_title">Login</span>
				<form onSubmit={onLoginHandler}>
					<div className="form_email">
						<label>Email Address</label>
						<input
							ref={emailRef}
							placeholder="Enter email address"
							type="email"
						/>
					</div>
					<div className="form_password">
						<label>Password</label>
						<input
							ref={passRef}
							placeholder="Enter password"
							type="password"
						/>
					</div>

					{error && <div className="form_error">{error}</div>}
					<Button className="btn_login" type="submit">
						Login
					</Button>
				</form>
			</div>
		</div>
	);
};

export default Login;
