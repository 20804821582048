import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';

import './styles.scss';

const AudioNote = props => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const audioRef = useRef(null);

    const handleOnTimeUpdate = () => {
        if (audioRef && audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
            setDuration(audioRef.current.duration);
        }
    };
    const handleButtonClick = () => {
        if (isPlaying) {
            setIsPlaying(false);
            audioRef.current.pause();
        } else {
            setIsPlaying(true);
            audioRef.current.play();
        }
    }

    return (
        <div className="audionote__container">
            <audio
                src={props.src}
                ref={audioRef}
                controls
                preload="auto"
                onTimeUpdate={handleOnTimeUpdate}
            />

            {props.label ? <div className="label">{props.label}</div> : null}
        </div>
    );
};

export default AudioNote;