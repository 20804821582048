import React from 'react';

import './styles.scss';

const PostImage = (props) => {
	return (
		<div className="post__image">
			<img src={props.src} alt={props.alt} style={props.style} />
			{props.desc && (
				<div className="post__image--desc">{props.desc}</div>
			)}
		</div>
	);
};

export default PostImage;
