import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

export const AddTags = (props) => {
	const [inputValue, setInputValue] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);
	const [suggestionsList, setSuggestionsList] = useState([]);

	const filterSuggestions = (value) => {
		const selectedArr = props.selectedTagsList.map((item) => item.name);

		const filteredList = props.tagsList.filter((item) => {
			return (
				((value && value.length === 0) ||
					item.name.toLowerCase().includes(value)) &&
				selectedArr.indexOf(item.name.toLowerCase()) === -1
			);
		});

		if (filteredList.length === 0) {
			filteredList.push({
				name: value,
				_id: null,
			});
		}
		console.log(filteredList);
		setSuggestionsList(filteredList);
		setShowDropdown(true);
	};

	const onInputChange = (event) => {
		const value = event.target.value;
		setInputValue(value);
		filterSuggestions(value);
	};

	const onKeydownHandler = (event) => {
		if (event.key === 'Enter' && inputValue) {
			props.addToSelectedList(inputValue, null);
			setInputValue('');
			filterSuggestions('');
		}
	};

	const dropdownItemClickHandler = (event) => {
		const name = event.target.getAttribute('data-name');

		const filter = props.tagsList.find((item) => item.name === name);

		if (filter) {
			props.addToSelectedList(filter.name, filter._id);
		} else {
			props.addToSelectedList(name, null);
		}

		setInputValue('');
	};

	return (
		<div className="addtags__container">
			<div className="addtags__container--selected">
				{props.selectedTagsList.map((item, index) => {
					return (
						<div className="tag__item" key={index}>
							<div className="tag__item--name">{item.name}</div>
						</div>
					);
				})}
			</div>
			<div className="addtags__container--input">
				<input
					value={inputValue}
					onChange={onInputChange}
					onKeyDown={onKeydownHandler}
					onFocus={() => {
						filterSuggestions(inputValue);
					}}
					onBlur={() => setShowDropdown(false)}
				/>
				{showDropdown ? (
					<div className="dropdown__container">
						{suggestionsList.map((item) => {
							if (item.name && item.name.length > 0) {
								return (
									<div
										key={item._id}
										className="dropdown__container--item"
										onMouseDown={dropdownItemClickHandler}
										data-name={item.name}
										data-id={item._id}
									>
										{item._id
											? item.name
											: `+ Create ${item.name}`}
									</div>
								);
							}
							return null;
						})}
					</div>
				) : null}
			</div>
		</div>
	);
};

AddTags.propTypes = {
	tagsList: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			_id: PropTypes.string.isRequired,
		})
	),
	selectedTagsList: PropTypes.array.isRequired,
	addToSelectedList: PropTypes.func.isRequired,
};
