import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import App from './app';
import store from './store/index.js';
import { getLocalStorage } from './utils/common.js';

import './index.scss';

const theme = getLocalStorage('APP_THEME') || 'dark';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<React.StrictMode>
				<HelmetProvider>
					<App theme={theme} />
				</HelmetProvider>
			</React.StrictMode>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
