import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	posts: [],
	isLoading: true,
	loadMore: true,
	pageNumber: 1,
	pageContent: 10,
};

const blogsSlice = createSlice({
	name: 'blog',
	initialState,
	reducers: {
		getBlogs(state) {
			state.isLoading = true;
		},

		blogsLoaded(state, action) {
			state.isLoading = false;
			const posts = action.payload.posts;

			state.pageNumber = 2;
			state.posts = posts;

			if (posts.length < state.pageContent) {
				state.loadMore = false;
			} else {
				state.loadMore = true;
			}
		},
	},
});

export const { actions, reducer, name: sliceKey } = blogsSlice;
