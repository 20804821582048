import React from 'react';

import SocialHandles from '../../components/SocialHandles';
import './styles.scss';

const AboutPage = (props) => {
	return (
		<section id="about">
			<div className="about__container">
				<div>
					<div className="about__container--desc">
						<p>
							Hi there, Abhishek here. I work at Info Edge India
							Ltd as a Software Engineer where I mostly focus on
							full stack development using technologies like{' '}
							<code>ReactJS</code>, <code>NodeJS</code>,{' '}
							<code>RabbitMQ</code>, <code>MySQL</code>, etc. I
							have also worked with and am proficient in{' '}
							<code>Python</code>, Computer Vision using{' '}
							<code>OpenCV</code>, and Android and Flutter
							Development using <code>Java</code>,{' '}
							<code>Kotlin</code> &amp; <code>Dart</code>.{' '}
						</p>
						<br />
						<p>
							I like reading and collecting books,
							<i>Jeffrey Archer</i>, <i>Terry Prachett</i> &amp;{' '}
							<i>Stephen King</i> are amongst my favorites authors.{' '}
						</p>
						<br />
						<p>
							<i>
								"It matters not how strait the gate, How
								charged with punishments the scroll,
							</i>
						</p>
						<p>
							<i>
								I am the master of my fate, I am the captain of
								my soul."
							</i>
						</p>
					</div>

					<SocialHandles />
				</div>
			<div className='component_circle'></div>
			</div>
		</section>
	);
};

export default AboutPage;
