import axios from '../utils/axios';

const getBlogs = (params) => {
	return axios
		.get('/blogs', { params })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export default getBlogs;
