import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const NotFound = (props) => {
	const title = props.title || '404 Not Found';
	const message = props.message || 'Cannot find the content you requested';
	return (
		<div className="notfound__container">
			<div>
				<span className="notfound__container--title">{title}</span>
				<span className="notfound__container--body">{message}</span>
			</div>
		</div>
	);
};

NotFound.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
};

export default NotFound;
