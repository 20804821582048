import React from 'react';

import './styles.scss';

const Projects = (props) => {
	const playstoreLink =
		'https://play.google.com/store/apps/details?id=com.moviesearch.watchmovies.mymovielist';
	const githubLink = 'https://github.com/abhishekti7';

	return (
		<div className="projects__container">
			<ul>
				<li>
					<a href={playstoreLink} rel="noreferrer" target="_blank">
						MyMovieList on Android Play Store
					</a>
				</li>
				<li>
					<a
						href={`${githubLink}/UnicornFilePicker`}
						rel="noreferrer"
						target="_blank"
					>
						UnicornFilePicker Library for Android on Github
					</a>
				</li>
				<li>
					<a
						href={`${githubLink}/RandomVideoCall`}
						rel="noreferrer"
						target="_blank"
					>
						Random Video Calling App for Android on Github
					</a>
				</li>
				<li>
					<a
						href={`${githubLink}/JavaFX-CodeEditor`}
						rel="noreferrer"
						target="_blank"
					>
						Code Editor using JavaFX on Github
					</a>
				</li>
				<li>
					<a
						href={`${githubLink}/GPA-Calculator`}
						rel="noreferrer"
						target="_blank"
					>
						GPA Calculator for Android on Github
					</a>
				</li>
				<li>
					<a
						href={`${githubLink}/py-calc`}
						rel="noreferrer"
						target="_blank"
					>
						Python Calculator using Python3 Tkinter on Github
					</a>
				</li>
				<li>
					<a
						href={`${githubLink}/SSS-Steam-Sale-Scraper`}
						rel="noreferrer"
						target="_blank"
					>
						Script to fetch list of games on Sale on Steam using
						Python on Github
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Projects;
