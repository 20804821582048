import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import './styles.scss';

const BlogCMSHeader = (props) => {
	const history = useHistory();

	return (
		<div className="blogcms__header">
			<div className="header__top">
				<div className="header__top--logo">
					<span>Admin Panel</span>
				</div>
			</div>
			<div className="header__bottom">
				<div className="header__bottom--links">
					<ul>
						<li>
							<Link to="/add-new">+Add New Post</Link>
						</li>
						<li>
							<Link to="/">Posts</Link>
						</li>
						<li>
							<a
								href={process.env.REACT_APP_CLIENT_URL}
								target="_blank"
								rel="noreferrer"
							>
								Go To Portfolio
							</a>
						</li>
						<li>
							<div
								onClick={() => {
									const option = window.confirm(
										'Are you sure you want to log out ?'
									);
									if (option) {
										history.replace('/logout');
									}
								}}
							>
								Logout
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default BlogCMSHeader;
