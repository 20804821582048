import React from "react";
import PropTypes from 'prop-types';

import Marquee from "react-fast-marquee";

import "./styles.scss";

const MarqueeHome = props => {
    return (
        <div className="marquee__container">
            <Marquee autoFill={true} direction="left" className="marquee__container--left">
                <div>
                    <span className="marquee-text">MongoDB</span>
                    <span className="marquee-text is-outline">MongoDB</span>
                </div>
                <div>
                    <span className="marquee-text">ExpressJS</span>
                    <span className="marquee-text is-outline">ExpressJS</span>
                </div>
                <div>
                    <span className="marquee-text">ReactJS</span>
                    <span className="marquee-text is-outline">ReactJs</span>
                </div>
                <div>
                    <span className="marquee-text">Node</span>
                    <span className="marquee-text is-outline">Node</span>
                </div>
            </Marquee>
            <Marquee autoFill={true} direction="right" className="marquee__container--right">
                <div>
                    <span className="marquee-text">Full Stack Development</span>
                    <span className="marquee-text is-outline">Full Stack Development</span>
                </div>
            </Marquee>
            <Marquee autoFill={true} direction="left" className="marquee__container--left">
                <div>
                    <span className="marquee-text">React Native</span>
                    <span className="marquee-text is-outline">React Native</span>
                </div>
                <div>
                    <span className="marquee-text">Android</span>
                    <span className="marquee-text is-outline">Android</span>
                </div>
            </Marquee>
        </div>
    );
};

export default MarqueeHome;