import React from 'react';
import './styles.scss';

const Loader = ({ style, type = 1 }) => {
	const className = type === 1 ? 'loading loaderScroller' : 'notifyLoader';
	return (
		<div style={style} className={className}>
			<div className="lds-spinner">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default Loader;
