import axios from '../utils/axiosAuth';

export default function postBlog(data) {
	return axios
		.post('/new-blog', data)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
}
