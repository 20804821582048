import axios from '../utils/axios';

const getTags = () => {
	return axios
		.get('/tags', {})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export default getTags;
