import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const CopyBar = (props) => {
	let inputRef = null;

	const onCopyHandler = () => {
		inputRef.select();
		document.execCommand('copy');
	};
	return (
		<div className="copybar__container">
			<input
				readOnly
				type="text"
				ref={(ref) => (inputRef = ref)}
				value={props.value}
				placeholder={props.placeholder}
			/>
			<div className="icon-copy" onClick={onCopyHandler}>
				<FontAwesomeIcon icon={faCopy} title="Copy Url" />
			</div>
		</div>
	);
};

export default CopyBar;
