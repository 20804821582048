import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faInstagram,
	faLinkedin,
	faGithub,
} from '@fortawesome/free-brands-svg-icons';

import './styles.scss';

const Footer = (props) => {
	const date = new Date();

	return (
		<div className="footer__container">
			<div className="footer__container--links">
				<a
					href="https://www.instagram.com/abhishek_ti7/"
					target="_blank"
					rel="noreferrer"
				>
					<FontAwesomeIcon
						className="icon"
						icon={faInstagram}
						title="Instagram"
					/>
				</a>
				<a
					href="https://www.linkedin.com/in/abhishek-tiwari7/"
					target="_blank"
					rel="noreferrer"
				>
					<FontAwesomeIcon
						className="icon"
						icon={faLinkedin}
						title="LinkedIn"
					/>
				</a>
				<a
					href="https://github.com/abhishekti7"
					target="_blank"
					rel="noreferrer"
				>
					<FontAwesomeIcon
						className="icon"
						icon={faGithub}
						title="GitHub"
					/>
				</a>
			</div>
			<div className="footer__container--body">
				<p>
					&copy; Abhishek Tiwari {date.getFullYear()}{' '}
					<a href="../assets/humans.txt">Crafted by Yours Truly</a>
				</p>
			</div>
		</div>
	);
};

export default Footer;
