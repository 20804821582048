/* eslint-disable eqeqeq */
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

export function getMonth(month) {
	if (month == 1) {
		return 'January';
	} else if (month == 2) {
		return 'February';
	} else if (month == 3) {
		return 'March';
	} else if (month == 4) {
		return 'April';
	} else if (month == 5) {
		return 'May';
	} else if (month == 6) {
		return 'June';
	} else if (month == 7) {
		return 'July';
	} else if (month == 8) {
		return 'August';
	} else if (month == 9) {
		return 'September';
	} else if (month == 10) {
		return 'October';
	} else if (month == 11) {
		return 'November';
	} else if (month == 12) {
		return 'December';
	}
}

export function checkIfUserLoggedIn() {
	let isLoggedIn = false;
	console.log(window.localStorage.getItem('AUTH_TOKEN'));
	if (window.localStorage && window.localStorage.getItem('AUTH_TOKEN')) {
		const authToken = window.localStorage.getItem('AUTH_TOKEN');
		const decoded = jwt_decode(authToken);
		const date = new Date();

		if (decoded.exp * 1000 < date.getTime()) {
			isLoggedIn = false;
		} else {
			isLoggedIn = true;
		}
	}
	return isLoggedIn;
}

export function deleteFromStorage(key) {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		// console.log(error);
	}
}

export function setLocalStorage(key, value) {
	try {
		localStorage.setItem(key, value);
	} catch (error) {
		// console.log(error);
	}
}

export function getLocalStorage(key) {
	try {
		return localStorage.getItem(key);
	} catch (error) {
		return null;
	}
}
