import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

export const Tag = (props) => {
	const tagData = props.data;
	return <li className="list_item">#{tagData.name}</li>;
};

Tag.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
			name: PropTypes.string,
		})
	),
};
