import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Footer from '../../components/Footer';
import BlogHeader from '../../components/BlogHeader';
import Posts from './Posts';
import Projects from './Projects';
import Post from './Post';
import Workstation from './Workstation';
import NotFound from '../../components/NotFound';
import BlogCrossDomain from './BlogCrossDomain';

import './styles.scss';

const Blog = (props) => {
	return (
		<section className="blog__section">
			<div className="blog__container">
				<BlogHeader />
				<Switch>
					<Route exact path="/">
						<Posts />
					</Route>
					<Route exact path="/projects">
						<Projects />
					</Route>
					<Route exact path="/post/:id">
						<Post />
					</Route>
					<Route exact path="/workstation">
						<Workstation />
					</Route>
					<Route exact path="/cross-domain">
						<BlogCrossDomain />
					</Route>
					<Route path="*">
						<NotFound
							message={`Could not locate ${window.location.pathname}`}
						/>
					</Route>
				</Switch>
				<Footer />
			</div>
		</section>
	);
};

export default Blog;
