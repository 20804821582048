import { useEffect, useRef } from 'react';

const useScrollListener = (onScrolled) => {
	const prevScrollY = useRef(0);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollY = window.scrollY;

			if (prevScrollY.current < currentScrollY) {
				onScrolled(currentScrollY, 'down');
			}
			if (prevScrollY.current > currentScrollY) {
				onScrolled(currentScrollY, 'up');
			}

			prevScrollY.current = currentScrollY;
		};

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => window.removeEventListener('scroll', handleScroll);
	}, [onScrolled]);
};

export default useScrollListener;
