import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PostsCard from '../../../components/PostsCard';
import getBlogs from '../../../../services/getBlogs';
import Loader from '../../../components/Loader';

import { checkIfUserLoggedIn } from '../../../../utils/common';

import './styles.scss';

const Posts = (props) => {
	const history = useHistory();
	const [posts, setPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [loadMore, setLoadMore] = useState(true);
	const [last, setLast] = useState(null);

	const loadMoreListener = (event) => {
		const a = window.innerHeight + document.documentElement.scrollTop;
		const b = document.scrollingElement.scrollHeight;
		if (!isLoading && loadMore & (a > b - 100)) {
			fetchBlogs();
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', loadMoreListener);

		return () => window.removeEventListener('scroll', loadMoreListener);
	});

	const fetchBlogs = async () => {
		setIsLoading(true);
		const pageContent = 10;
		const result = await getBlogs({
			pageContent,
			fromDate: last,
		});

		if (result.data) {
			setPosts((prevArr) => {
				return [...prevArr, ...result.data];
			});
			// eslint-disable-next-line eqeqeq
			if (result.data.length == pageContent) {
				setLast(result.data[pageContent - 1].timestamp._seconds);
				setLoadMore(true);
			} else {
				setLoadMore(false);
			}
		}
		setIsLoading(false);
	};

	useEffect(() => {
		const isLoggedIn = checkIfUserLoggedIn();

		if (!isLoggedIn) {
			history.push('/login');
		}
		fetchBlogs();
	}, []);

	const contentArr = posts.map((item) => {
		const link = `/post/${item.titleId}`;
		return (
			<PostsCard
				key={item.id}
				className="admin__posts"
				data={item}
				onClick={() => {
					history.push(link);
				}}
			/>
		);
	});
	return (
		<div className="blogs__container">
			{isLoading && (
				<div className="spinner-loader">
					<Loader />
				</div>
			)}
			{contentArr}
		</div>
	);
};

export default Posts;
