import React, { useEffect, useState } from 'react';
import ThemeContext, { initialThemeState } from './ThemeContext';

import { getLocalStorage } from '../utils/common';

const ThemeProvider = ({ initialTheme, children }) => {
	const [theme, setTheme] = useState(initialTheme);

	useEffect(() => {
		const savedThemeLocal = getLocalStorage('APP_THEME') || 'dark';

		if (savedThemeLocal) {
			setTheme(savedThemeLocal);
		}

		const onStorageListener = (event) => {
			if (event.newValue) {
				setTheme(event.newValue);
			}
		};

		if (window.localStorage) {
			window.addEventListener('storage', onStorageListener);
		}

		return () => {
			if (window.localStorage) {
				return window.removeEventListener('storage', onStorageListener);
			}
		};
	}, []);

	return (
		<ThemeContext.Provider value={{ theme, setTheme }}>
			<div className={`theme--${theme}`}>{children}</div>
		</ThemeContext.Provider>
	);
};

export default ThemeProvider;
