import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import BlogCMSHeader from '../../components/BlogCMSHeader';
import Posts from './Posts';
import Post from './Post';
import AddNewPost from './AddNewPost';
import Login from './Login';

import { checkIfUserLoggedIn } from '../../../utils/common';

import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';

const BlogCMS = (props) => {
	const history = useHistory();

	useEffect(() => {
		const isLoggedIn = checkIfUserLoggedIn();

		if (!isLoggedIn) {
			history.push('/login');
		}
	}, []);

	return (
		<div className="blog__cms">
			<BlogCMSHeader />
			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnVisibilityChange
				draggable={false}
				pauseOnHover
			/>
			<Switch>
				<Route exact path={['/']}>
					<Posts />
				</Route>
				<Route exact path="/add-new">
					<AddNewPost />
				</Route>
				<Route exact path="/post/:id/edit">
					<AddNewPost />
				</Route>
				<Route exact path="/posts">
					<Posts />
				</Route>
				<Route path="/post/:id">
					<Post />
				</Route>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route exact path="/logout">
					<Login />
				</Route>
			</Switch>
		</div>
	);
};

export default BlogCMS;
