import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Button = (props) => {
	return (
		<button
			className={`button ${props.className || ''}`}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			{props.children}
		</button>
	);
};

Button.propTypes = {
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};
export default Button;
