import axios from '../utils/axios';

const login = async (data) => {
	return axios
		.post('/login', data)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
};

export default login;
