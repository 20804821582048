import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Heading = (props) => {
	return <h1 className={`heading ${props.className || ''}`}>{props.text}</h1>;
};

Heading.propTypes = {
	text: PropTypes.string,
	className: PropTypes.string,
};

export default Heading;
