import axios from '../utils/axiosAuth';

export default function postImage(data) {
	return axios
		.post('/image', data)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
}
