import React from 'react';
import ReactDOM from 'react-dom';

import './styles.scss';

const AddImagesModal = (props) => {
	const modalContent = (
		<div className="modal__background">
			<div className="modal__container">{props.children}</div>
		</div>
	);

	return ReactDOM.createPortal(modalContent, document.getElementById('root'));
};

export default AddImagesModal;
