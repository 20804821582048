import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';

export function configureAppStore() {
	const reduxSagaMonitorOptions = {};
	const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
	const { run: runSaga } = sagaMiddleware;

	const middlewares = [sagaMiddleware];

	const enhancers = [
		createInjectorsEnhancer({
			createReducer,
			runSaga,
		}),
	];

	const store = configureStore({
		reducer: createReducer(),
		middleware: [...getDefaultMiddleware(), ...middlewares],
		devTools: process.env.NODE_ENV !== 'production',
		enhancers,
	});

	// make reducers hot reloadable
	if (module.hot) {
		module.hot.accept('./reducers', () => {
			forceReducerReload(store);
		});
	}

	return store;
}
