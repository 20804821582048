import React, { useContext } from 'react';

import ThemeContext from '../../../store/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

import { setLocalStorage } from '../../../utils/common';

import './styles.scss';

const ThemeSetter = (props) => {
	const { theme, setTheme } = useContext(ThemeContext);

	const onInputChange = () => {
		const messageData = {
			key: 'APP_THEME',
		};
		if (theme === 'dark') {
			messageData.value = 'light';
			setTheme('light');
			setLocalStorage('APP_THEME', 'light');
		} else {
			messageData.value = 'dark';
			setTheme('dark');
			setLocalStorage('APP_THEME', 'dark');
		}

		// post messsage to iframe for communication across sub domains
		const win = document.getElementById('ifr').contentWindow;
		if (win) {
			win.postMessage(
				JSON.stringify({ key: 'storage', data: messageData }),
				'*'
			);
		}
	};
	const labelStyles = {};
	const ballStyles = {};
	const sunStyles = {};
	const moonStyles = {};

	if (theme === 'dark') {
		moonStyles.opacity = '1';
		sunStyles.opacity = '0';
		ballStyles.transform = 'translateX(0rem)';
	} else {
		moonStyles.opacity = '0';
		sunStyles.opacity = '1';
		ballStyles.transform = 'translateX(2.7rem)';
	}

	if (props.bgColor) {
		labelStyles.backgroundColor = `${props.bgColor}`;
	}

	if (props.ballColor) {
		ballStyles.backgroundColor = `${props.ballColor}`;
	}

	if (props.sunColor) {
		sunStyles.color = `${props.sunColor}`;
	}

	return (
		<div className="switch__container">
			<input type="checkbox" id="switch" />
			<label htmlFor="switch" onClick={onInputChange} style={labelStyles}>
				<i>
					<FontAwesomeIcon
						style={sunStyles}
						icon={faSun}
						className="icon-sun"
					/>
				</i>
				<i>
					<FontAwesomeIcon
						style={moonStyles}
						icon={faMoon}
						className="icon-moon"
					/>
				</i>
				<span className="ball" style={ballStyles}></span>
			</label>
		</div>
	);
};

export default ThemeSetter;
