import axios from '../utils/axiosAuth';

export default function getAllImages() {
	return axios
		.get('/image', {})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			return err;
		});
}
