import React from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const ScrollToTop = (props) => {
	const to = props.to === 'blog' ? 'blog__head' : 'home';

	return (
		<div className={`scrolltop__container ${props.className}`}>
			<Link to={to} smooth={true} duration={400}>
				<div className="scrolltop__container--icon">
					<FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>
				</div>
			</Link>
		</div>
	);
};

export default ScrollToTop;
