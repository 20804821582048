import { all, select, takeLeading, call, put } from 'redux-saga/effects';

import { selectLast, selectPageContent } from './selectors';

import getBlogs from '../../../services/getBlogs';

import { actions } from './slice';

export function* getBlogPosts() {
	const last = yield select(selectLast);
	const pageContent = yield select(selectPageContent);

	try {
		const result = yield call(getBlogs, {
			pageContent,
			fromDate: last,
		});

		if (result.data) {
			yield put(actions.blogsLoaded({ posts: result.data }));
		}
	} catch (error) {
		console.log(error);
	}
}

export function* myBlogSaga() {
	yield all([yield takeLeading(actions.getBlogs.type, getBlogPosts)]);
}
