import React from 'react';

import { Table } from 'semantic-ui-react';

import './styles.scss';

const setUpInfo = [
	{
		type: 'Monitor',
		value: 'Acer Nitro VG240YS',
		desc: '23.8inch, FHD 1920X1080, 165Hz, IPS panel',
		from: 'Amazon',
	},
	{
		type: 'Monitor Stand',
		value: 'amazon basics Monitor Stand',
		desc: '360-Degree Rotation, Adjustable Positioning and Tiltable Angle, Cable-Management System',
		from: 'Amazon'
	},
	{
		type: 'Motherboard',
		value: 'MSI B550 PRO-VDH WiFi',
		desc: 'DDR4, Dual M.2 slots, USB 3.2 Gen 1, Type C input in front panel, Wi-Fi, Micro ATX, Ryzen 5000 compatible',
		from: 'MD Computers',
	},
	{
		type: 'CPU',
		value: 'AMD Ryzen 5 5600X',
		desc: '6 cores 12 threads, 3.7GHz',
		from: 'Amazon',
	},
	{
		type: "UPS",
		value: 'APC 1.1KVA 230V UPS',
		desc: '',
		from: 'MD Computers'
	},
	{
		type: 'Graphics Card',
		value: 'Zotac Nvidia GTX 1650',
		desc: '',
		from: 'MD Computers',
	},
	{
		type: 'RAM',
		value: 'G Skill Ripjaws V 16GB (8X2)',
		desc: '3200MHz',
		from: 'MD Computers',
	},
	{
		type: 'SSD',
		value: 'Western Digital SN570 (New Generation)',
		desc: 'NVMe, 3500Mb/s Read',
		from: 'Amazon',
	},
	{
		type: 'HDD',
		value: 'Western Digital WD10EZEX Blue 1TB',
		desc: '7200 RPM, 64Mb Cache, SATA 6 GB/s',
		from: 'MD Computers',
	},
	{
		type: 'Power Supply Unit',
		value: 'Cooler Master MWE Gold 750 V2 80+ Gold Certified',
		desc: 'Fully Modular, 750W',
		from: 'MD Computers',
	},
	{
		type: 'Cabinet',
		value: 'Lian Li Lancool 2',
		desc: 'Mesg RBG Cabinet, comes with 3 fans and awesome air circulation structure',
		from: 'MD Computers',
	},
	{
		type: 'Keyboard',
		value: 'Redragon SU-RARA K582 RGB Mechanical (Blue Switches)',
		desc: 'Blue Switches',
		from: 'Amazon',
	},
	{
		type: "Mouse",
		value: 'Corsair Katar Pro, 12400 DPI (Black)',
		desc: 'Good grip, amazing companion software, and light-weight',
		from: 'Amazon'
	},
	{
		type: 'Gamepad',
		value: 'Redgear Pro Wireless',
		desc: 'Integrated Dual Intensity Motor, Illuminated Keys',
		from: 'Amazon',
	},
];
const Workstation = (props) => {
	return (
		<div className="workstation__container">
			<div className="title">
				I use the following setup for mid-level gaming and for personal
				use. I play games like CS:GO, Witcher 3, Dying Light, PUBG, etc.
				I get a constant FPS of 250+ in CS:GO (which I later set to a
				max threshold).
			</div>

			<div className='workstation__container--img'>
				<div className='img-block'>
					<img src='https://portfolio-blog-images.s3.ap-south-1.amazonaws.com/workstation_day.jpg' alt='workstartion during day'/>
					<div className='label'>Workstation during the day</div>
				</div>
				<div className='img-block'>
					<img src='https://portfolio-blog-images.s3.ap-south-1.amazonaws.com/workstation_night.jpg' alt='workstartion during night'/>
					<div className='label'>Workstation during the night</div>
				</div>
			</div>

			<div className="table-container">
				<Table striped={true} className="table">
					<Table.Header className="table-header">
						<Table.Row className="table-header-row">
							<Table.HeaderCell
								width="one"
								className="table-header-row-cell"
							>
								Component
							</Table.HeaderCell>
							<Table.HeaderCell
								width="two"
								className="table-header-row-cell"
							>
								Description
							</Table.HeaderCell>
							<Table.HeaderCell
								width="one"
								className="table-header-row-cell"
							>
								Purchased From
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body className="table-body">
						{setUpInfo.map((item, index) => {
							return (
								<Table.Row
									className="table-body-row"
									key={index}
								>
									<Table.Cell
										width="one"
										verticalAlign="top"
										textAlign="center"
										className="table-body-row-cell"
									>
										{item.type}
									</Table.Cell>
									<Table.Cell
										width="two"
										verticalAlign="top"
										textAlign="center"
										className="table-body-row-cell"
									>
										<div>
											<span className="main-text">
												{item.value}
											</span>
											<span className="sub-text">
												{item.desc}
											</span>
										</div>
									</Table.Cell>
									<Table.Cell
										width="one"
										verticalAlign="top"
										textAlign="center"
										className="table-body-row-cell"
									>
										{item.from}
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</div>
		</div>
	);
};

export default Workstation;
