import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import MarkDown from '../../../components/MarkDown';
import Loader from '../../../components/Loader';
import getBlog from '../../../../services/getBlog';
import { Tag } from '../../../components/Tag';

import { getMonth } from '../../../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const Post = (props) => {
	const { id } = useParams();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(false);
	const [blogData, setBlogData] = useState(null);

	useEffect(() => {
		const fetchBlog = async () => {
			return await getBlog({ blogId: id });
		};

		fetchBlog().then((data) => {
			if (data && data.data && data.data.length > 0) {
				setBlogData(data.data[0]);
			}
			setIsLoading(false);
		});
	}, [id]);

	let content;
	if (isLoading) {
		content = <Loader />;
	} else if (!isLoading && !blogData) {
		content = (
			<div className="not-found">
				Sorry! we could not find the article.
			</div>
		);
	} else {
		const timestamp = moment(blogData.timestamp);
		content = (
			<div className="cms_post">
				<div className="post__heading">
					<div className="post__heading--date">
						<span className="date">{timestamp.format('D')}</span>
						<span className="month">
							{getMonth(timestamp.format('M'))}
						</span>
						<span className="year">{timestamp.format('YYYY')}</span>
					</div>
					<div className="post__heading--body">
						<p className="title">{blogData.title}</p>
						<p className="desc">{blogData.description}</p>
					</div>
					<div
						className="post__heading--edit"
						onClick={() => {
							history.replace(`/post/${id}/edit`);
						}}
					>
						<FontAwesomeIcon icon={faEdit} />
					</div>
				</div>
				<div className="post__body">
					<div className="tags__container">
						{blogData.tags && blogData.tags.length > 0 ? (
							<div className="tags__container--label">Tags: </div>
						) : null}
						<ul className="tags__container--list">
							{blogData.tags.map((item, index) => {
								return <Tag data={item} key={index} />;
							})}
						</ul>
					</div>
					<MarkDown markdown={blogData.content} />
				</div>
			</div>
		);
	}

	return <div className="cmspost__container">{content}</div>;
};

export default Post;
