import axios from '../utils/axiosAuth';

export default function deleteBlog(id) {
	return axios
		.delete('/blog/' + id, {})
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			return error;
		});
}
