import React, { useCallback, useState } from 'react';

import ScrollToTop from '../ScrollToTop';
import useScrollListener from '../../../utils/useScrollListener';
import './styles.scss';

const Layout = (props) => {
	const [showScrollTop, setShowScrollTop] = useState(false);

	const onScrolled = useCallback((scrollTop, direction) => {
		if (scrollTop >= window.innerHeight / 2) {
			setShowScrollTop(true);
		} else {
			setShowScrollTop(false);
		}
	}, []);
	useScrollListener(onScrolled);

	return (
		<div className="container">
			<div className="page-content">
				{props.children}
				<ScrollToTop
					className={showScrollTop ? '' : 'hidden'}
					to={props.to}
				/>
			</div>
		</div>
	);
};

export default Layout;
