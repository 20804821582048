import React, { useEffect, useState } from 'react';
import { useHistory, Prompt, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import DateTime from 'react-datetime';

import MarkDown from '../../../components/MarkDown';
import AddImagesModal from '../AddImagesModal';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import CopyBar from '../../../components/CopyBar';
import ImageContainer from '../../../components/ImageContainer';
import { AddTags } from '../../../components/AddTags';

import { checkIfUserLoggedIn } from '../../../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

import getBlog from '../../../../services/getBlog';
import updateBlog from '../../../../services/updateBlog';
import postImage from '../../../../services/postImage';
import postBlog from '../../../../services/postBlog';
import getAllImages from '../../../../services/getAllImages';
import deleteBlog from '../../../../services/deleteBlog';
import getTags from '../../../../services/getTags';

import 'react-datetime/css/react-datetime.css';
import './styles.scss';

const AddNewPost = (props) => {
	const history = useHistory();
	const { id } = useParams();
	const [dataController, setDataController] = useState({
		titleId: '',
		title: '',
		description: '',
		content: '',
		timestamp: moment().valueOf(),
		filename: '',
		file: null,
		tags: [],
	});

	const [changes, setChanges] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [error, setError] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [images, setImages] = useState([]);
	const [tags, setTags] = useState([]);
	const [copyValue, setCopyValue] = useState('');
	const [blogData, setBlogData] = useState(null);

	const fetchImages = () => {
		getAllImages().then((data) => {
			const files = data.data;
			setImages(files);
		});
	};

	const fetchTags = () => {
		getTags().then((data) => {
			setTags(data.data);
		});
	};

	const setData = (key, value) => {
		setDataController((prevObj) => {
			const newObj = { ...prevObj };
			newObj[key] = value;
			return newObj;
		});
	};

	useEffect(() => {
		const isLoggedIn = checkIfUserLoggedIn();

		if (!isLoggedIn) {
			history.push('/login');
		}

		if (history.location.pathname.indexOf('edit') !== -1) {
			getBlog({ blogId: id })
				.then((res) => {
					setBlogData(res.data[0]);
					setData('titleId', res.data[0].titleId);
					setData('title', res.data[0].title);
					setData('description', res.data[0].description);
					setData('content', res.data[0].content);
					setData(
						'timestamp',
						res.data[0].timestamp || moment().valueOf()
					);
					setData('tags', res.data[0].tags || []);
				})
				.catch((err) => {
					console.log(err);
					history.replace('/');
				});
		}
		fetchImages();
		fetchTags();
	}, []);

	const onSubmitHandler = async () => {
		if (
			!dataController.title ||
			!dataController.titleId ||
			!dataController.description ||
			!dataController.content
		) {
			setError('All fields are required');
			return;
		}

		const data = {
			title: dataController.title,
			description: dataController.description,
			content: dataController.content,
			timestamp: dataController.timestamp,
			titleId: dataController.titleId,
			tags: dataController.tags,
		};

		let response;

		if (blogData) {
			data.blogId = id;
			response = await updateBlog(data);
		} else {
			response = await postBlog(data);

			if (response.status === 'success') {
				setChanges(false);
				history.replace('/');
			} else {
				setError('HTTP request failed');
			}
		}

		if (response.status === 'success') {
			setChanges(false);
			history.replace('/');
		} else {
			setError('HTTP request failed');
		}
	};

	const submitHandler = (event) => {
		event.preventDefault();
	};

	const imageList = images.map((image, index) => {
		return (
			<ImageContainer
				key={index}
				url={image.url}
				name={image.filename}
				onClick={(url) => setCopyValue(url)}
			/>
		);
	});

	const onFileSelect = (event) => {
		setData('file', event.target.files[0]);
	};

	const onFileUpload = async () => {
		if (dataController.file) {
			const formData = new FormData();

			formData.append(
				'image',
				dataController.file,
				dataController.file.name
			);
			formData.append('imageName', dataController.filename);

			const response = await postImage(formData);
			if (response.data) {
				setImages([]);
				fetchImages();
				toast.success('Image Added successfully');
			} else {
				toast.success('Request Failed');
			}
		}
	};

	const onDeleteHandler = async () => {
		const response = await deleteBlog(id);

		if (response.data) {
			toast.success('Post deleted successfully');
			history.replace('/');
		} else {
			toast.error('Something went wrong');
		}
	};

	const modalContent = (
		<div
			className="addimages__modal"
			style={{
				padding: '2rem',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
			}}
		>
			<div style={{ flex: '1' }}>
				<Heading text="Add Images" />

				<CopyBar
					value={copyValue}
					placeholder="Select an image to copy URL"
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignContent: 'center',
					}}
				>
					<div>
						<input
							type="text"
							style={{
								padding: '0.6rem 1.5rem',
							}}
							placeholder="Enter filename"
							onChange={(event) =>
								setData('filename', event.target.value)
							}
						/>
						<Button onClick={onFileUpload}>Upload</Button>
					</div>
					<div
						style={{
							alignSelf: 'center',
						}}
					>
						<input type="file" onChange={onFileSelect} />
					</div>
				</div>
				<div
					style={{
						margin: '2rem 0',
						display: 'grid',
						gridTemplateColumns:
							'repeat(auto-fill, minmax(150px, 3fr))',
						gap: '2rem',
					}}
				>
					{images ? imageList : null}
				</div>
			</div>
			<div
				style={{ display: 'inline-block' }}
				className="modal-close"
				onClick={() => setOpenModal(false)}
			>
				<FontAwesomeIcon
					style={{ fontSize: '2.4rem' }}
					icon={faWindowClose}
				/>
			</div>
		</div>
	);

	return (
		<div className="addnew__container">
			<Prompt when={changes} message="You have unsaved changes" />

			{openModal && <AddImagesModal>{modalContent}</AddImagesModal>}

			<Heading text="Add New Post" />

			{error && <div className="error">{error}</div>}

			<form
				className="addnew__container--form"
				onSubmit={submitHandler}
			>
				<div className="form__input">
					<label>Title ID*</label>
					<input
						name="input-titleid"
						placeholder="Enter Post Title ID"
						value={
							dataController && dataController.titleId
								? dataController.titleId
								: ''
						}
						onChange={(event) => {
							const value = event.target.value;
							setData('titleId', value);
						}}
					/>
				</div>
				<div className="form__input">
					<label>Title*</label>
					<DateTime
						value={
							dataController && dataController.timestamp
								? moment(dataController.timestamp)
								: moment()
						}
						dateFormat="DD-MM-YYYY"
						timeFormat={false}
						onChange={(date) => {
							setData('timestamp', date.valueOf());
						}}
					/>
				</div>
				<div className="form__input">
					<label>Title*</label>
					<input
						name="input-timestamp"
						placeholder="Enter Post Title"
						value={
							dataController && dataController.title
								? dataController.title
								: ''
						}
						onChange={(event) => {
							const value = event.target.value;
							setData('title', value);
						}}
					/>
				</div>
				<div className="form__input">
					<label>Description*</label>
					<input
						name="input-desc"
						placeholder="Enter Post Desription"
						value={
							dataController && dataController.description
								? dataController.description
								: ''
						}
						onChange={(event) => {
							const value = event.target.value;
							setData('description', value);
						}}
					/>
				</div>

				<div className="form__input">
					<label>Tags</label>
					<AddTags
						tagsList={tags}
						selectedTagsList={dataController.tags}
						addToSelectedList={(name, id) => {
							if (!id) {
								setDataController((prevValue) => {
									const newTagsList = [...prevValue.tags];
									const existingTag = newTagsList.find(
										(item) =>
											item.name.toLowerCase() ===
											name.toLowerCase()
									);

									if (!existingTag) {
										newTagsList.push({ name, _id: null });
									}

									return {
										...prevValue,
										tags: newTagsList,
									};
								});
							} else {
								setDataController((prevValue) => {
									const newList = [...prevValue.tags];
									newList.push({
										name,
										_id: id,
									});
									return {
										...prevValue,
										tags: newList,
									};
								});
							}
						}}
					/>
				</div>

				<div>
					<label>Content*</label>
					<textarea
						className="input-content"
						value={
							dataController && dataController.content
								? dataController.content
								: ''
						}
						onChange={(event) => {
							setData('content', event.target.value);
						}}
					/>
				</div>

				{showPreview && <MarkDown markdown={dataController.content} />}

				<div className="btns">
					<Button
						onClick={(event) => {
							const confirm = window.confirm('Post this blog?');
							if (confirm) {
								onSubmitHandler();
							}
						}}
					>{`${blogData ? 'Edit' : 'Add'} Post`}</Button>

					<Button
						onClick={() => {
							setShowPreview((prevValue) => !prevValue);
						}}
					>
						Preview
					</Button>

					<Button
						onClick={() => {
							setOpenModal(true);
						}}
					>
						View/Add Images
					</Button>

					{blogData ? (
						<Button
							onClick={() => {
								const option = window.confirm(
									'Delete this Post? You may not be able to recover it once deleted'
								);
								if (option) {
									onDeleteHandler();
								}
							}}
						>
							Delete Post
						</Button>
					) : null}
					<Button
						onClick={() => {
							history.goBack();
						}}
					>
						Cancel
					</Button>
				</div>
			</form>
		</div>
	);
};

export default AddNewPost;
