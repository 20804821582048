import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import Loader from '../../../components/Loader';
import PostsCard from '../../../components/PostsCard';

import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { reducer, sliceKey, actions } from '../slice';
import { selectPosts, selectIsLoading, selectLoadMore } from '../selectors';
import { myBlogSaga } from '../saga';

import './styles.scss';

const Posts = (props) => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: myBlogSaga });

	const dispatch = useDispatch();
	const history = useHistory();

	const posts = useSelector(selectPosts);
	const isLoading = useSelector(selectIsLoading);
	const loadMore = useSelector(selectLoadMore);

	useEffect(() => {
		const loadMoreListener = (event) => {
			const a = window.innerHeight + document.documentElement.scrollTop;
			const b = document.scrollingElement.scrollHeight;

			if (!isLoading && loadMore && a > b - 100) {
				dispatch(actions.getBlogs());
			}
		};

		window.addEventListener('scroll', loadMoreListener);

		return () => window.removeEventListener('scroll', loadMoreListener);
	}, [loadMore, isLoading]);

	useEffect(() => {
		if (posts.length === 0) {
			dispatch(actions.getBlogs());
		}
	}, []);

	const blogsLists = posts.map((item) => {
		const link = `/post/${item.titleId}`;
		return (
			<PostsCard
				key={item.id}
				data={item}
				onClick={() => {
					// open link if not already at the same location
					// eslint-disable-next-line eqeqeq
					if (window.location.pathname != link) {
						history.push(link);
					}
				}}
			/>
		);
	});

	let mainContent;
	if (isLoading && posts.length === 0) {
		mainContent = (
			<div className="spinner-loader">
				<Loader />
			</div>
		);
	} else if (isLoading && posts.length > 0) {
		mainContent = (
			<>
				{blogsLists}
				<div className="spinner-loader">
					<Loader />
				</div>
			</>
		);
	} else {
		mainContent = <>{blogsLists}</>;
	}

	return <div className="posts__container">{mainContent}</div>;
};

export default Posts;
