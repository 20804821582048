import React from 'react';

import moment from 'moment';
import { getMonth } from '../../../utils/common';
import PropTypes from 'prop-types';

import './styles.scss';

const PostsCard = (props) => {
	const data = props.data;
	const className = props.className;

	const parsedDate = moment(data.timestamp);
	const day = parsedDate.format('D');
	const month = getMonth(parsedDate.format('M'));
	const year = parsedDate.format('YYYY');

	return (
		<div className={`posts__card ${className || ''}`}>
			<div className="posts__card--date">
				<span className="date">{day}</span>
				<span className="month">{month}</span>
				<span className="year">{year}</span>
			</div>
			<div className="posts__card--desc">
				<p className="title" onClick={props.onClick}>
					{data.title}
				</p>
				<p className="desc">{data.description}</p>
			</div>
		</div>
	);
};

PostsCard.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default PostsCard;
